import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { PlayersNameProvider } from "./components/providers/PlayersName";
import { AuthContextProvider } from "./state/AuthContext";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

if (process.env.NODE_ENV === "production") disableReactDevTools();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <AuthContextProvider>
      <PlayersNameProvider>
        <App />
      </PlayersNameProvider>
    </AuthContextProvider>
  </>
);
